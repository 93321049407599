<template>
    <v-container fluid>
        <v-row dense justify="center" align="center">
            <v-col >
             <img
            
              src="../../assets/logo_master.png"
              class="logo"
             />
            </v-col>
        </v-row>
      <v-row dense>
        <v-col
          v-for="(card,index) in cards"
          :key="index"
          cols="12"
          md="4"
          sm="12"
        >
         <v-card max-width="300" class="mx-auto">
             <v-card-title  class="mt-0 ml-0 mr-0 pt-0 pr-0 pl-0 mb-0 pb-0">
                   <v-toolbar dense color="primary">
                       <v-toolbar-title> <span class="white--text"> {{card.titulo}}</span> </v-toolbar-title>
                    </v-toolbar>
              </v-card-title>
              <v-card-text>
            <v-row dense  justify="center" align="center">
                <v-col cols="6">
                  <v-img
                    :src=" card.src"
                    class="white--text"
                    height="auto"
                    width="100%"
                  ></v-img>
                </v-col>
            </v-row>
              </v-card-text>

            <v-card-actions class="pr-0 pl-0 pb-0 pt-0">
              <v-spacer/>

              <v-btn  :to="card.to" block color="primary">
               {{card.buttonName}}
              </v-btn>
              <v-spacer/>
            
            </v-card-actions>
          </v-card>
        </v-col>
  </v-row>
    </v-container>

</template>

<script>
export default {
    data(){
        return {
            cards: [
                {
                    titulo:'Orçamento',
                    src: require('../../assets/Icons/orcamento.png'),
                    buttonName: 'Acessar Orçamento',
                    to: '/orcamentos'
                },
                 {
                    titulo:'Clientes',
                    src: require('../../assets/Icons/cliente.png'),
                    buttonName: 'Acessar Clientes',
                    to: '/clientes'
                },
                 {
                    titulo:'Usuários',
                    src: require('../../assets/Icons/user.png'),
                    buttonName: 'Acessar Usuários',
                    to: '/usuarios'
                }
            ]
        }
    }
}
</script>

<style>
.logo {
display: flex;
margin-left: auto;
margin-right: auto;
width: 100%;
padding-bottom: 35px;
max-width: 500px;
margin-top: 2%;
}
</style>